import VueRouter from 'vue-router';
const routes =[
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login.vue')
    },
    {
        path:'/index',
        name:'index',
        component:()=>import('../components/IndexPage.vue'),
        children:[
            {
                path:'/homeWithParams/:pageNum/:pageSize',
                name:'home',
                component:()=>import('../components/Home.vue')
            },
            {
                path:'/home',
                name:'home',
                component:()=>import('../components/Home.vue')
            },
            {
                path:'/userHome',
                name:'userHome',
                component:()=>import('../components/UserHome.vue')
            },
            {
                path:'/chatHome',
                name:'chatHome',
                component:()=>import('../components/chatHome.vue')
            },
            {
                path:'/course',
                name:'course',
                component:()=>import('../components/course.vue')
            },

            {
                path:'/userControl',
                name:'userControl',
                component:()=>import('../components/userControl.vue')
            },
            {
                path:'/PostControl',
                name:'PostControl',
                component:()=>import('../components/PostControl.vue')
            },
        ]
    }

]
const router = new VueRouter({
    mode:'hash',
    routes
})
export default router